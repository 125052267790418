import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faPhone,
  faHeadphones,
} from '@fortawesome/free-solid-svg-icons';
import { getDoc, doc } from 'firebase/firestore';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Page from '../components/Page';
import { firestore } from '../firebase';
import Visibility from '../components/Visibility';

const COLLECTION_NAME = 'setting';

const INITIAL_DATA = {
  address: 'Lorong',
  phone: '+60-123456789',
  facebook: 'https://facebook.com',
  instagram: 'https://instagram.com',
  companyContact: '+60-13 8901921',
  description:
    'This is my main compnay product This is my main compnay product This is my main compnay product This is my main compnay product This is my main compnay product This is my main compnay product',
};

const AboutUs = () => {
  const [aboutImage, setAboutImage] = useState('');
  const [detail, setDetail] = useState({ ...INITIAL_DATA });

  const fetchDetail = useCallback(async () => {
    const docRef = doc(firestore, [COLLECTION_NAME, COLLECTION_NAME].join('/'));
    const result = await getDoc(docRef);
    const data = result.data() as any;
    setAboutImage(data?.imageUrl);
    setDetail(data);
  }, []);

  useEffect(() => {
    fetchDetail();
  }, [fetchDetail]);

  return (
    <Page title="About Us">
      <div className="row">
        <Visibility show={!!aboutImage}>
          <div className="col-12 col-md-6 pr-1">
            <img className="img-fluid" src={aboutImage} alt="img" />
          </div>
        </Visibility>
        <div className="col-12 col-md-6 d-flex flex-column justify-content-around">
          <table>
            <tbody>
              <tr>
                <td width="35">
                  <div className="my-2">
                    <FontAwesomeIcon
                      className="text-primary"
                      icon={faMapMarkerAlt}
                    />
                  </div>
                </td>
                <td align="left">Address</td>
                <td align="left">{detail.address}</td>
              </tr>

              <tr>
                <td width="35">
                  <div className="my-2">
                    <FontAwesomeIcon className="text-primary" icon={faPhone} />
                  </div>
                </td>
                <td align="left">Phone</td>
                <td align="left">{detail.phone}</td>
              </tr>

              <tr>
                <td width="35">
                  <div className="my-2">
                    <FontAwesomeIcon
                      className="text-primary"
                      icon={faFacebook}
                    />
                  </div>
                </td>
                <td align="left">Facebook</td>
                <td align="left">
                  <a target="_blank" href={detail.facebook} rel="noreferrer">
                    {detail.facebook}
                  </a>
                </td>
              </tr>

              <tr>
                <td width="35">
                  <div className="my-2">
                    <FontAwesomeIcon
                      className="text-primary"
                      icon={faInstagram}
                    />
                  </div>
                </td>
                <td align="left">Instagram</td>
                <td align="left">
                  <a target="_blank" href={detail.instagram} rel="noreferrer">
                    {detail.instagram}
                  </a>
                </td>
              </tr>

              <tr>
                <td width="35">
                  <div className="my-2">
                    <FontAwesomeIcon
                      className="text-primary"
                      icon={faHeadphones}
                    />
                  </div>
                </td>
                <td align="left">Company Contact</td>
                <td align="left">{detail.companyContact}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-12 mt-4 mb-4">
          <div
            className="mt-4 mb-4 text-start lh-lg description-content"
            dangerouslySetInnerHTML={{
              __html: detail?.description,
            }}
          />
        </div>
      </div>
    </Page>
  );
};

export default AboutUs;
