import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Slider from 'react-slick';
import { get, map } from 'lodash';
import clsx from 'clsx';
import { doc, getDoc } from 'firebase/firestore';
import Page from '../components/Page';
import Visibility from '../components/Visibility';
import { firestore, logUserEvent } from '../firebase';

const ProductBlock = styled.div`
  min-height: 600px;
  align-content: baseline;
`;

const ProductDescBlock = styled.div`
  flex-grow: 1;
  max-width: 500px;
`;

const ProductImage = styled.img`
  width: 400px;
  height: 300px;
  max-height: 300px;
  margin-bottom: 10px;
`;

const ProductThumbnailBlock = styled.div`
  width: 60px;
  height: 40px;
  cursor: pointer;
  position: relative;

  border: 2px solid var(--bs-secondary);

  &.active {
    border: 2px solid var(--bs-primary);
  }

  &:hover {
    &:after {
      content: '';
      background: #80808055;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
`;

const ProductThumbnail = styled.img`
  max-height: 100%;
  object-fit: contain;
  object-position: center;
  margin: auto;
`;

const CarouselBlock = styled.div`
  width: 400px;
`;

const ProductLabel = styled.div`
  width: 150px;
  text-align: left;
  font-weight: 500;
`;

const ProductText = styled.div`
  width: 150px;
  text-align: left;
  font-weight: 500;
`;

const ProductDescription = styled.div`
  text-align: left;
`;

type CarouselSliderProps = {
  productId: any;
  imageUrls: string[];
};

type ProductDescProps = {
  label: string;
  text: string;
};

const PRODUCT_COLLECTION = 'product';

const CarouselSlider: React.FunctionComponent<CarouselSliderProps> = ({
  productId,
  imageUrls,
}) => {
  const [selectedIndex, selectIndex] = useState(0);
  const selectedImage = get(imageUrls, selectedIndex || 0);
  const sliderRef = useRef<Slider>(null);

  const settings = {
    ref: sliderRef,
    dots: false,
    infinite: true,
    speed: 500,
    centerPadding: '0px',
    centerMode: true,
    initialSlide: 0,
    slidesToScroll: 1,
    slidesToShow: Math.min(imageUrls.length, 3),
    focusOnSelect: true,
    afterChange: (index: number) => {
      selectIndex(index);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '20px',
          slidesToScroll: 1,
          slidesToShow: Math.min(imageUrls.length, 3),
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: '0px',
          slidesToScroll: 1,
          slidesToShow: Math.min(imageUrls.length, 3),
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: '20px',
          slidesToScroll: 1,
          slidesToShow: Math.min(imageUrls.length, 4),
          initialSlide: 0,
        },
      },
    ],
  };

  if (!imageUrls.length) {
    return null;
  }

  return (
    <div className="d-flex flex-column justify-content-center">
      <ProductImage className="" src={selectedImage} alt="product-img" />
      <div className="mx-5">
        <Slider className="product-slider" {...settings}>
          {imageUrls.map((imageUrl: string, idx) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`img-${idx}`}
              className="d-flex justify-content-center"
            >
              <ProductThumbnailBlock
                className={clsx({
                  active: selectedIndex === idx,
                })}
                onClick={() => {
                  logUserEvent('product_slick_click', {
                    productId,
                  });
                  sliderRef.current?.slickGoTo(idx);
                  selectIndex(idx);
                }}
                key={`img-${idx}`}
              >
                <ProductThumbnail
                  className="img-fluid h-100"
                  src={imageUrl}
                  alt="img"
                />
              </ProductThumbnailBlock>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

const ProductDesc: React.FunctionComponent<ProductDescProps> = ({
  label,
  text,
}) => (
  <Visibility show={!!text}>
    <div className="d-flex flex-wrap mb-2">
      <ProductLabel className="pe-2">{label}</ProductLabel>
      <ProductText>{text || '-'}</ProductText>
    </div>
  </Visibility>
);

const INITIAL_DETAIL = {
  imageUrls: [],
  title: '',
  category: '',
  sno: '',
  brand: '',
  usability: '',
  instruction: '',
  description: '',
};

const ProductDetail = () => {
  const { id } = useParams<{ id: string }>();

  const [detail, setDetail] = useState({
    ...INITIAL_DETAIL,
  });

  const fetchDetail = useCallback(async () => {
    try {
      // Fetch Product Detail
      if (!id) {
        return;
      }
      const docRef = doc(firestore, [PRODUCT_COLLECTION, id].join('/'));
      const result = await getDoc(docRef);
      const data: any = result.data();
      setDetail({ ...data, imageUrls: map(data.imageUrls, 'imageUrl') });
    } catch (e) {
      console.error(e);
    }
  }, [id]);

  useEffect(() => {
    fetchDetail();
  }, [fetchDetail]);

  return (
    <Page title={detail?.title} showBack>
      <ProductBlock className="row">
        <div className="col-12 d-flex flex-wrap justify-content-center">
          <CarouselBlock className="mb-4 mb-md-0">
            <CarouselSlider productId={id} imageUrls={detail?.imageUrls} />
          </CarouselBlock>
          <ProductDescBlock className="d-flex flex-column ps-0 ps-md-5">
            <ProductDesc label="Name" text={detail?.title} />
            <ProductDesc label="Brand" text={detail?.brand} />
            <ProductDesc label="Category" text={detail?.category} />
            <ProductDesc label="Usablity" text={detail?.usability} />
            <ProductDesc label="Product Number" text={detail?.sno} />
            <ProductDesc label="Other Instruction" text={detail?.instruction} />
          </ProductDescBlock>
        </div>

        <div className="col-12 d-flex flex-column justify-content-start align-items-start mt-3 mb-5">
          <span className="title mb-3">Detail Information</span>

          <Visibility show={!detail.description}>
            <h4 className="text-center text-muted w-100 mt-2">
              No More Information
            </h4>
          </Visibility>

          <Visibility show={!!detail.description}>
            <ProductDescription
              className="text-muted description-content"
              dangerouslySetInnerHTML={{
                __html: detail.description,
              }}
            />
          </Visibility>
        </div>
      </ProductBlock>
    </Page>
  );
};

export default ProductDetail;
