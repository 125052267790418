import React from 'react';

const Footer = () => (
  <footer className="footer mt-auto mx-0 px-0 bg-light">
    <div className="container">
      <div className="row justfy-content-around">
        <div className="col">
          <small className="text-white fw-bold">
            Copyright © 2020-2021 Fortune Technology
          </small>
        </div>
        {/* <div className="col">
          <small className="text-white fw-bold caption">ICP0000000000-0</small>
        </div> */}
      </div>
    </div>
  </footer>
);

export default Footer;
