import React from 'react';
import Page from '../components/Page';

const Home = () => (
  <Page title="Home">
    <div />
  </Page>
);

export default Home;
