import clsx from 'clsx';
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getDocs, query, collection, where, orderBy } from 'firebase/firestore';
import { first, map } from 'lodash';
import Page from '../components/Page';
import { firestore } from '../firebase';

const ProductImage = styled.img`
  height: 300px;
  max-height: 300px;
`;

const ProductBlock = styled.div`
  min-height: 500px;
`;

const ProductItemContent = styled.div`
  position: relative;
  &:hover {
    &:after {
      content: '';
      background: #80808055;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
`;

type ProductItemProps = {
  id: any;
  categoryId?: string;
  imageUrls: string[];
  title: string;
  className?: string;
};

const PRODUCT_COLLECTION = 'product';

const ProductItem: React.FunctionComponent<ProductItemProps> = ({
  id,
  categoryId,
  imageUrls,
  title,
  className,
}) => {
  const history = useHistory();
  return (
    <div className={className}>
      <ProductItemContent
        onClick={() => history.push(`/product/${categoryId}/${id}`)}
        className={clsx('d-flex flex-column pointer img-fluid')}
      >
        <ProductImage
          className="w-100 img-fluid"
          src={first(imageUrls)}
          alt="product-img"
        />
        <span className="py-1 text-start">{title}</span>
      </ProductItemContent>
    </div>
  );
};

ProductItem.defaultProps = {
  className: '',
};

const Product = () => {
  const { cate } = useParams<{ cate: string }>();

  const [products, setProducts] = useState<ProductItemProps[]>([]);

  const fetchProduct = useCallback(async () => {
    try {
      setProducts([]);
      // Fetch product
      const result = await getDocs(
        query(
          collection(firestore, PRODUCT_COLLECTION),
          where('categoryId', '==', cate),
          orderBy('createdAt', 'asc'),
        ),
      );

      const newData = result.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .map((i: any) => ({
          ...i,
          imageUrls: map(i?.imageUrls, 'imageUrl'),
        }));

      console.log(newData);
      setProducts(newData as any);
    } catch (e) {
      console.error(e);
    }
  }, [cate]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  return (
    <Page title="Product">
      <ProductBlock className="row mb-4">
        {!products.length ? (
          <div className="flex text-muted justify-center h5 mt-4">
            No Product Items
          </div>
        ) : null}

        {products.map((product) => (
          <ProductItem
            key={product.id}
            className="col-12 col-md-6 col-lg-4 col-xl-3 my-2 mb-3"
            categoryId={cate}
            {...product}
          />
        ))}
      </ProductBlock>
    </Page>
  );
};

export default Product;
