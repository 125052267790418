import React, { useState, useMemo, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getDocs, query, collection, where } from 'firebase/firestore';
import { orderBy } from 'lodash';
import { firestore } from '../firebase';

const LOGOIMG = styled.img`
  height: 30px;
`;

type HeaderItemProps = {
  href?: string;
  dropdown?: React.ReactElement | null;
  children: React.ReactNode | string;
  className?: string;
};

type Route = {
  href?: string;
  title: string;
  dropdown?: React.ReactElement;
};

const CATEGORY_COLLECTION = 'category';

const HeaderItem: React.FunctionComponent<HeaderItemProps> = ({
  href,
  dropdown,
  children,
  className,
}) => {
  const { pathname } = useLocation();

  const active = pathname === href;

  if (dropdown) {
    return (
      <li className="nav-item dropdown">
        <a
          className={clsx('nav-link dropdown-toggle', className)}
          href="#"
          id="navbarDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {children}
        </a>
        {dropdown}
      </li>
    );
  }

  return (
    <li
      className={clsx('nav-item', {
        active,
      })}
    >
      <Link
        className={clsx('nav-link', className)}
        aria-current="page"
        to={href as any}
      >
        {children}
      </Link>
    </li>
  );
};

HeaderItem.defaultProps = {
  href: '#',
  dropdown: undefined,
  className: '',
};

const ProductDropdown = () => {
  const [categories, setCategories] = useState<any[]>([]);

  const fetchCategories = useCallback(async () => {
    try {
      // Fetch Categories
      const categoryRef = collection(firestore, CATEGORY_COLLECTION);
      const result = await getDocs(
        query(categoryRef, where('publish', '==', true)),
      );

      const data = orderBy(
        result.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })),
        'name',
        'asc',
      );
      setCategories(data as any);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const categoriesRoutes = useMemo(
    () =>
      categories.map<Route>((cate) => ({
        href: `/product/${cate.id}`,
        title: cate.name,
      })),
    [categories],
  );

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <ul className="dropdown-menu bg-dark" aria-labelledby="navbarDropdown">
      {categoriesRoutes.map((route) => (
        <HeaderItem key={route.href} className="text-white" href={route.href}>
          {route.title}
        </HeaderItem>
      ))}
    </ul>
  );
};

const routes: Route[] = [
  // {
  //   href: '/',
  //   title: 'Home',
  // },
  {
    title: 'Product',
    href: '/product',
    dropdown: <ProductDropdown />,
  },
  {
    href: '/about-us',
    title: 'About Us',
  },
];

const Header = () => (
  <nav className="navbar navbar-expand-md navbar-light bg-light">
    <div className="container-fluid container-md">
      <Link className="navbar-brand" to="/">
        <LOGOIMG className="img-fluid" alt="logo512" src="/logo512.png" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div
        className="collapse navbar-collapse ml-5"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav me-auto mb-2 mb-md-0">
          {routes.map((route) => (
            <HeaderItem
              key={route.href}
              dropdown={route.dropdown}
              href={route.href}
            >
              {route.title}
            </HeaderItem>
          ))}
        </ul>
      </div>
    </div>
  </nav>
);

export default Header;
