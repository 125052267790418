import React from 'react';

type VisibilityProps = {
  show: boolean;
  children: React.ReactNode | React.ReactNodeArray | any;
};

const Visibility: React.FunctionComponent<VisibilityProps> = ({
  show,
  children,
}) => {
  if (!show) {
    return null;
  }

  return children;
};

export default Visibility;
