import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Visibility from './Visibility';

type PageProps = {
  children: React.ReactNode;
  title?: string;
  showBack?: boolean;
};

const CustomButton = styled.div`
  border-radius: 10px;
  &:hover {
    background: lightgrey;
  }
`;

const Page: React.FunctionComponent<PageProps> = ({
  title,
  children,
  showBack,
}) => {
  const history = useHistory();
  return (
    <div className="page container">
      <div className="row mb-4">
        <div className="col">
          <span className="title">{title}</span>
        </div>

        <Visibility show={showBack || false}>
          <div className="col-12 mt-2 text-left d-flex">
            <CustomButton
              className="text-primary px-2 py-1 pointer"
              onClick={() => history.goBack()}
            >
              <FontAwesomeIcon
                className="text-primary me-2"
                icon={faChevronLeft}
              />
              Back
            </CustomButton>
          </div>
        </Visibility>
      </div>
      {children}
    </div>
  );
};

Page.defaultProps = {
  title: 'Title',
  showBack: false,
};

export default Page;
