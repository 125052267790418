import React, { useContext } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { LAYOUT_CONTEXT } from '../contexts/LayoutContext';

const CustomImage = styled.img`
  object-fit: cover;
  object-position: center;
  margin: auto;
  max-height: 350px;
`;

const Banners = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const { banners } = useContext(LAYOUT_CONTEXT);

  return (
    <Slider className="banners" {...settings}>
      {banners.map((banner: string) => (
        <CustomImage
          key={`banner-${banner}`}
          className="img-fluid"
          src={banner}
        />
      ))}
    </Slider>
  );
};

export default Banners;
