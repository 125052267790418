import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import logo from './logo.svg';
import Routes from './Routes';
import Header from './layouts/Header';
import Banners from './layouts/Banners';
import { LAYOUT_PROVIDER } from './contexts/LayoutContext';
import Footer from './layouts/Footer';
import './firebase';

function App() {
  return (
    <div className="App">
      <div
        className="container-fluid px-0 d-flex flex-column"
        style={{
          minHeight: '100vh',
        }}
      >
        <Router>
          <LAYOUT_PROVIDER>
            <Header />
            <div className="row px-0 mx-0">
              <div className="col-12 px-0 mx-0">
                <Banners />
              </div>
            </div>
            <div className="py-3" />
            <Switch>{Routes}</Switch>
            <Footer />
          </LAYOUT_PROVIDER>
        </Router>
      </div>
    </div>
  );
}

export default App;
