import React from 'react';
import { Route } from 'react-router-dom';
import AboutUs from './pages/AboutUs';
import Home from './pages/Home';
import Product from './pages/Product';
import ProductDetail from './pages/ProductDetail';

type ROUTE = {
  path: string;
  exact?: boolean;
  component: React.ReactNode;
};

const routes: ROUTE[] = [
  {
    path: '/',
    exact: true,
    component: () => <Home />,
  },
  {
    path: '/product/:cate',
    exact: true,
    component: () => <Product />,
  },
  {
    path: '/product/:cate/:id',
    exact: true,
    component: () => <ProductDetail />,
  },
  {
    path: '/about-us',
    exact: true,
    component: () => <AboutUs />,
  },
];

const Routes: React.ReactElement[] = routes.map((route) => (
  <Route key={route.path} path={route.path} exact={route.exact}>
    {route.component}
  </Route>
));

export default Routes;
