import React, { useState, useCallback, useEffect } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { map, orderBy } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { firestore, logUserEvent } from '../firebase';

const COLLECTION_NAME = 'banner';

export const LAYOUT_CONTEXT = React.createContext<Record<string, any>>({});

type LayoutProviderProps = {
  children: React.ReactNode;
};
export const LAYOUT_PROVIDER: React.FunctionComponent<LayoutProviderProps> = ({
  children,
}) => {
  const history = useHistory();
  const params = useParams();
  const [banners, setBanners] = useState<string[]>([]);

  history.listen(() => {
    logUserEvent(history.location.pathname, params);
  });

  const fetchBanners = useCallback(async () => {
    try {
      // TODO: Logic
      const docRef = doc(
        firestore,
        [COLLECTION_NAME, COLLECTION_NAME].join('/'),
      );
      const result = await getDoc(docRef);

      const data = result.data();

      const newBanners = map(orderBy(data?.banners, 'order'), 'imageUrl');

      setBanners(newBanners);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners]);

  const value = {
    banners,
  };
  return (
    <LAYOUT_CONTEXT.Provider value={value}>{children}</LAYOUT_CONTEXT.Provider>
  );
};
